import './style.css';
import {Map, View} from 'ol';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
import {Modify, Draw, DragPan} from 'ol/interaction';
import Icon from 'ol/style/Icon.js';
import Style from 'ol/style/Style';

//Crear estilo de marcador 
const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 20],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'geo-alt-fill.svg',
    scale: 1.5,
  }),
});

//Creación de capas
const vectorSource = new VectorSource();

const vectorLayer = new VectorLayer({
  source: vectorSource,
});

const rasterLayer = new TileLayer({
  source: new OSM(),
});

//Creación del mapa
const map = new Map({
  layers: [rasterLayer, vectorLayer],
  target: 'map',
  view: new View({
    center: [0, 0],
    projection: "EPSG:4326",
    zoom: 1
  }),
});

//Modificación de la posición del marcador
const modify = new Modify({
  hitDetection: vectorLayer,
  source: vectorSource,
});

let precision = 7;
let selectedFeature = null;

modify.on('modifyend', function (event) {
  event.features.forEach(function (feature) {
    const markerGeometry = feature.getGeometry();
    const markerCoordinates = markerGeometry.getCoordinates();
    //document.getElementById('coordinateDisplay').textContent = `${markerCoordinates[0].toFixed(precision)}, ${markerCoordinates[1].toFixed(precision)}`;
    
    selectedFeature = feature;
    console.log("modifyend selectedFeature.ol_uid:", selectedFeature.ol_uid);
    console.log("modifyend selectedFeature:", selectedFeature);
  });
});

map.addInteraction(modify);

map.on('click', function(evt) {
  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  });

  if (feature) {
    selectedFeature = feature;
    console.log("click selectedFeature.ol_uid:", selectedFeature.ol_uid);
    console.log("click selectedFeature:", selectedFeature);
    const markerGeometry = feature.getGeometry();
    const markerCoordinates = markerGeometry.getCoordinates();
    const displayString = `${markerCoordinates[0].toFixed(precision)}, ${markerCoordinates[1].toFixed(precision)}`;
    document.getElementById('coordinateDisplay').textContent = displayString;
    console.log(markerCoordinates);
  } else {
    selectedFeature = null;
    document.getElementById('coordinateDisplay').textContent = "No marker clicked";
    console.log("No marker clicked");
  }
});

//Crear nuevos marcadores
let draw; 
let featureId = null;

function createMarkerFeature(coordinates){
  const newFeature = new Feature({
      geometry: new Point(coordinates),
  });
  newFeature.setStyle(iconStyle);
  return newFeature;
};

function addInteractions() {
  map.getInteractions().forEach(function (interaction) {
    if (interaction instanceof DragPan) {
        interaction.setActive(false);
    }
  });

  draw = new Draw({
    source: vectorSource,
    type: 'Point',
  });

  map.addInteraction(draw);

  draw.on('drawend', function (event) {
    const feature = event.feature;
    const coordinates = feature.getGeometry().getCoordinates();
    const markerName = prompt("Id:");

    feature.setStyle(iconStyle); 
    if (markerName) {
      feature.set('name', markerName);
    } else {
      feature.set('name', 'defaultId');
    }

    featureId = feature;

    console.log("Feature added by Draw:", feature);
    console.log(vectorSource.getFeatures().map(f => f.ol_uid));

    map.removeInteraction(draw);  
  });

  map.once('click', function () {
    map.removeInteraction(draw);
    map.getInteractions().forEach(function (interaction) {
      if (interaction instanceof DragPan) {
        interaction.setActive(true);
      }
    });
  });
};

document.getElementById('newLocationButton').addEventListener('click', function () {
  addInteractions();
});

//Borrar marcadores
document.addEventListener('keydown', function (event) {
  if (event.key === 'Delete' || event.key === 'Supr') {
    if (featureId) {
      vectorSource.removeFeature(featureId);
      featureId = null; 
    } else if (selectedFeature) { 
       vectorSource.removeFeature(selectedFeature);
       selectedFeature = null;
    }
    console.log("vectorSource features after delete:", vectorSource.getFeatures());
  }      
});

//Copiar al portapapeles
document.getElementById('copyButton').addEventListener('click', function () {
  const features = vectorSource.getFeatures();
    if (features.length > 0) {
        const lastFeature = features[features.length - 1]; 
        const markerGeometry = lastFeature.getGeometry();
        const markerCoordinates = markerGeometry.getCoordinates();
        const lastMarkerCoordinates = `${markerCoordinates[0].toFixed(precision)}, ${markerCoordinates[1].toFixed(precision)}`;

        document.getElementById('coordinateDisplay').textContent = lastMarkerCoordinates;

        navigator.clipboard.writeText(lastMarkerCoordinates).then(() => {
            alert('Coordinates copied to clipboard');
        }).catch(err => {
            console.error('No se pudo copiar las coordenadas', err);
        });
    }
});

console.log(vectorSource.getFeatures())

//Descarga formato GeoJson de los puntos marcados
document.getElementById('download').addEventListener('click', function () {
  console.log("download vectorSource ol_uids:", vectorSource.getFeatures().map(f => f.ol_uid));

  const geojsonFormat = new GeoJSON();
  const features = vectorSource.getFeatures();

  const uniqueFeatures = [];
  const seenCoordinates = new Set();

  features.forEach(feature => {
      const coordinates = feature.getGeometry().getCoordinates().toString(); 
      if (!seenCoordinates.has(coordinates)) {
          uniqueFeatures.push(feature);
          seenCoordinates.add(coordinates);
      }
  });

  const geojson = geojsonFormat.writeFeatures(features, {
      featureProjection: map.getView().getProjection(),
  });

  console.log(geojson)

  const blob = new Blob([geojson], { type: 'application/json' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'marked_points.geojson';
  link.click();
});


